import { styled } from '@mui/material/styles';

import publicPath from '@/utils/functions/publicPath';

const LoginContainer = styled('div')(() => ({
  height: '100%',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
}));

export const LoginWrapper = styled('div')(() => ({
  backgroundImage: `url(${publicPath('/images/loginBg.webp')})`,
  backgroundSize: 'cover',
  minHeight: '100vh',
  width: '100%',
  display: 'grid',
  gridTemplateRows: '1fr auto',
  gridTemplateColumns: 'minmax(0, 1fr)',
}));

export default LoginContainer;
