import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

const LoginItemWrapper = styled('div')(({ theme }) => ({
  cursor: 'pointer',
  position: 'relative',
  maxWidth: '343px',
  width: '100%',
  height: '180px',
  backgroundColor: theme.palette.background.cardExtraLayer,
  borderRadius: theme.spacing(2.5),
  display: 'flex',
  gap: theme.spacing(2.5),
  backgroundSize: 'cover',
  justifyContent: 'flex-end',
  alignItems: 'flex-end',
  padding: theme.spacing(3, 2.5),
  [theme.breakpoints.up('tablet')]: {
    width: '320px',
    height: '356px',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(4),
  },
  ':after': {
    borderRadius: 'inherit',
    height: '100%',
    width: '100%',
    content: '""',
    top: 0,
    position: 'absolute',
    background:
      'linear-gradient(180deg, rgba(26, 51, 118, 0.00) 17.49%, #1A3376 70.51%)',
    opacity: 0,
    transition: 'opacity 900ms linear',
    left: 0,
  },

  ':before': {
    borderRadius: 'inherit',
    height: '100%',
    width: '100%',
    content: '""',
    top: 0,
    position: 'absolute',
    background:
      'linear-gradient(180deg, rgba(34, 67, 155, 0.00) 17.49%, #22439B 70.51%)',
    opacity: 1,
    transition: 'opacity 250ms linear',
    left: 0,
  },

  '&:hover': {
    ':after': {
      transitionDuration: '250ms',
      opacity: 1,
    },

    ':before': {
      transitionDuration: '900ms',
      opacity: 0,
    },

    '.MuiButtonBase-root': {
      backgroundColor: theme.palette.primary.hover,
    },
  },
}));

export const LoginInnerItemWrapper = styled(Grid)(({ theme }) => ({
  display: 'flex',
  zIndex: 10,
  alignItems: 'flex-start',
  flexDirection: 'column',
  [theme.breakpoints.up('tablet')]: {
    alignItems: 'center',
    gap: theme.spacing(0.5),
  },
}));

export default LoginItemWrapper;
